import React from 'react';

import "../css/HeroSection.css"

import HeroSection from './HeroSection';


const HeroSectionBuild = () => {
    return (
        <section>
           <nav class="navbar  navbar-expand-lg navbar-dark ">
        <a class="navbar-brand" href="/">Glaticsoft</a>
         <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                  <a class="nav-link" href="/contactus">Contact</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="/aboutUs">About</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#services">Services</a>
              </li>
          </ul>
       </div>
      </nav>
      <HeroSection/>
      </section>
    )
}

export default HeroSectionBuild;