import React from 'react';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';



import BlogPage from './components/aboutSection';
import MainComponent from './components/mainComponent';
import ContactForm from './components/ContactForm';



function App() {
  return (
    <div className="App">
  
      <BrowserRouter>
          <Routes>
          <Route path="/" element={ <MainComponent/>  }/>
               <Route path="/aboutUs" element={  <BlogPage/> } />
               <Route path='/contactus' element={ <ContactForm/>}/>       
          </Routes>
       </BrowserRouter>

       {/* <HeroSection />
       <LogoList logos={logos}/>
       <ServicesSection />
       <ParallaxContactSection  /> */}
    </div>
  );
}

export default App;
