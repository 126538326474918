
import Card from 'react-bootstrap/Card';
import { aboutFunctions } from '../constants/constant';
import React from 'react';
import "../css/aboutSection.css"
import { Collapse } from 'react-bootstrap';

function WithValuesCard() {
  return (
      aboutFunctions.map((adt, index) => (
       <div className='col-md-4 col-sm-12 values-card'>
       <Collapse in={true} appear={true}>
       <Card key={index} border='info' bg='dark'>
      <Card.Header><h4>{adt.title}</h4></Card.Header>
      <Card.Body>
        <Card.Text>
          {adt.text}
        </Card.Text>
      </Card.Body>
    </Card>
       </Collapse>
     
       </div> 
       
      ))
  
  );
}

export default WithValuesCard;