import React from 'react';
import '../css/scrolable.css'; // Create a CSS file for styling

const LogoList = ({ logos }) => {
  return (
    <div className="logo-list-container">
      <div>
        <h1>Our Tech Stack</h1>
      </div>
      <div className="logo-list">
        {logos.map((logo, index) => (
          <div className="logo" key={index}>
            <img src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoList;
