import { 
     appDev , webDev , contentImage, digitalMarket, socialMedia,  gameDev   } from '../assets';

const blogData = [
{
    title: "App Development",
    description: "Our app development team is at the forefront of cutting-edge technology, creating user-friendly and visually stunning mobile applications. Whether you need an iOS, Android, or cross-platform app, we have the expertise to turn your vision into a reality. From concept to deployment, we ensure your app stands out in the competitive app market.",
    src: `${appDev}`
},
{
    title: "Web Development",
    description: "In today's digital landscape, your website is often the first point of contact with your audience. We specialize in building responsive, feature-rich, and SEO-optimized websites that not only look great but also perform seamlessly. Our web development services encompass everything from e-commerce platforms to corporate websites, custom web applications, and more.",
    src: `${webDev}`
},
{
    title: "Digital Marketing",
    description: "Unlock the full potential of your digital presence with our comprehensive digital marketing services. Our team of digital marketing experts leverages the latest strategies and tools to boost your online visibility, engage your target audience, and drive conversions. Whether it's SEO, PPC advertising, email marketing, or content marketing, we've got you covered.",
    src: `${digitalMarket}`
},
{
    title: "Game Development",
    description: "Gaming is more than just entertainment; it's a billion-dollar industry. Our game development team crafts immersive and captivating gaming experiences for various platforms. Whether you have a game concept in mind or need assistance in game design, development, or monetization, we have the skillset and passion to bring your gaming dreams to life.",
    src: `${gameDev}`
},
{
    title: "Cross-Platform Application Development",
    description: "Save time and resources with our cross-platform application development services. We use state-of-the-art frameworks like React Native and Flutter to create apps that work seamlessly on both iOS and Android platforms. This approach ensures you reach a broader audience without compromising on quality.",
    src: `${contentImage}`
},

{
    title: "Social Media Marketing",
    description: "In the age of social media, having a strong online presence is vital for success. Our social media marketing experts create customized strategies to increase brand awareness, engagement, and customer loyalty. We'll help you harness the power of social media platforms to connect with your audience effectively.",
    src: `${socialMedia}`
},
]

const aboutFunctions = [
     {
        title:"Expertise",
        text: "Our team comprises seasoned professionals with years of experience in their respective domains."
     },
     {
        title:"Innovation",
        text: "We stay updated with the latest industry trends and technologies to deliver cutting-edge solutions"
     },
     {
        title:"Quality",
        text: "We are committed to delivering high-quality, bug-free products that meet and exceed your expectations"
     },
     {
        title:"Affordability",
        text: "We offer competitive pricing without compromising on the quality of our services"
     },
     {
        title:"Client-Centric",
        text: "Your success is our success, and we prioritize open communication and client satisfaction."
     },
     {
        title:"Comprehensive Approach",
        text: "We craft a complete tech ecosystem for your app, covering the backend, admin interface, and CMS, with specialized teams for a holistic solution."
     },
]



export {
    blogData,
    aboutFunctions
}