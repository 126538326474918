
import ParallaxContactSection from '../components/ContactSection.jsx';
import LogoList from '../components/ScrolableSection.jsx';
import ServicesSection from "../components/ServicesSection.jsx";


import { flutter, python, js , mongodb , react, firebase,  cplus, csharp, css, html, awsIcon, kotlin, java, sql   } from '../assets';

import React from 'react';
import HeroSectionBuild from "./HeroSectionBuild.jsx";

const logos = [
    { src: `${flutter}`, alt: 'Logo 1' },
    { src: `${python}`, alt: 'Logo 2' },
    { src: `${js}`, alt: 'Logo 3' },
    { src: `${mongodb}`, alt: 'Logo 4' },
    { src: `${react}`, alt: 'Logo 5' },
    { src: `${firebase}`, alt: 'Logo 6' },
  
    { src: `${cplus}`, alt: 'Logo 8' },
    { src: `${csharp}`, alt: 'Logo 9' },
    { src: `${css}`, alt: 'Logo 10' },
    { src: `${html}`, alt: 'Logo 11' },
    { src: `${awsIcon}`, alt: 'Logo 12' },
    { src: `${kotlin}`, alt: 'Logo 13' },
    { src: `${java}`, alt: 'Logo 14' },
    { src: `${sql}`, alt: 'Logo 15' },
    // Add more logos as needed
  ];



const MainComponent = () => {
 


    return (
     
    <div className="App">
      {/* <BrowserRouter>
        <Routes>
          <Route path="/aboutus" element={<BlogPage/>}>    
          </Route>
        </Routes>
      </BrowserRouter> */}
        {/* <CollapsibleExample/> */}
        <HeroSectionBuild/>
       <LogoList logos={logos}/>
       <ServicesSection />
       <ParallaxContactSection  />
    
    </div>
    )
}

export default MainComponent