// src/components/HeroSection.js
import React, { lazy } from 'react';
import { Parallax } from 'react-parallax';
import "../css/HeroSection.css"
import { heroOne } from '../assets';
import { Button, } from "react-bootstrap";

const HeroSection = () => {
  return (
    <Parallax bgImage={heroOne} strength={500} blur={{ min: -15, max: 15 }} loading={lazy} lazy={true}>
      <div className="hero">
        <div className="hero-content ">
          <h1>Revolutionize Your Business with Cutting-Edge Software Solutions: Unleash the Power of Innovation Today!</h1>
          <p>Are you prepared to take your company to the next level? 
            Our software service firm is ready to assist you in achieving unrivaled achievement. You'll be able to simplify operations, boost productivity, and improve customer experiences like never before with our unique and configurable software solutions. Explore a world of possibilities as we collaborate to make your vision a reality. Welcome to a future where efficiency meets quality, made possible by our software solutions expertise.
           Elevate your business today and join me on this amazing adventure!</p>
           <div className="d-grid gap-2 py-2">
        <Button  type="submit" className="hero-button border border-info my-2" size="lg" href='/aboutus'  >
           <h5>Learn more</h5>
        </Button>
        </div>
        </div>
      </div>
    </Parallax>
  );
};

export default HeroSection;
