import React, { lazy } from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import "../css/ServicesSection.css"; // Create this CSS file to style your component
import { contentImage, digitalMarket, socialMedia, webDev, appDev, gameDev } from '../assets';



const ServicesSection = () => {
  return (
    <ParallaxProvider >
    <section className="services-section" id='services' >
        <h1>Our Services</h1>
        <div className="services-container">
          <ServiceCard
            title="Digital Marketing"
            description="Unlock the full potential of your digital presence with our comprehensive digital marketing services."
            imageSrc={digitalMarket}
          />
          <ServiceCard
            title="Social Media Marketing"
            description="Our social media marketing experts create customized strategies to increase brand awareness, engagement, and customer loyalty"
            imageSrc={socialMedia}
          />
          <ServiceCard
            title="Mobile Application Development"
            description="Whether you need an iOS, Android, or cross-platform app, we have the expertise to turn your vision into a reality."
            imageSrc={appDev}
          />
          <ServiceCard
            title="Web Development"
            description="We specialize in building responsive, feature-rich, and SEO-optimized websites that not only look great but also perform seamlessly. "
            imageSrc={webDev}
          />
          <ServiceCard
            title="Game Development"
            description="Our game development team crafts immersive and captivating gaming experiences for various platforms."
            imageSrc={gameDev}
          />
          <ServiceCard
            title="Cross platform application development in flutter"
            description="Save time and resources with our cross-platform application development services."
            imageSrc={contentImage}
          />
          {/* Add more service cards here */}
        </div>
        <div>

        </div>
      </section>

    </ParallaxProvider>
  );
};

const ServiceCard = ({ title, description, imageSrc }) => {
  return (
    <Parallax className="service-card" y={[-20, 20]} tagOuter="figure" blur={{ min: -15, max: 15 }} loading={lazy}>
      <figure>
        <img src={imageSrc} alt={title} loading='lazy'/>
      </figure>
      <div className="service-info">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </Parallax>
  );
};

export default ServicesSection;
