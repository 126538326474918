
import '../css/aboutSection.css';
import React, { useEffect, useState } from 'react';
import { blogData } from '../constants/constant';
import { Button } from 'react-bootstrap';
import WithValuesCard from '../constants/commanCard';




const BlogPage = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="blog-container">
      <h1 className={`scroll-animation ${scrollY > 100 ? 'scale-down' : 'scale-up'}`}>Welcome to GlaticSoft - Your Partner in Digital Excellence!</h1>
      <p className={`scroll-animation ${scrollY >100 ? 'scale-down' : 'scale-up'}`}>
      At GlaticSoft, we believe in turning ideas into reality, one line of code at a time. We are a dynamic team of tech enthusiasts and creative minds dedicated to crafting digital solutions that empower businesses and individuals to thrive in the digital age. With a passion for innovation and a commitment to excellence, 
      we offer a wide range of services to cater to all your digital needs.
      </p>
      <h1 className={`scroll-animation ${scrollY > 100 ? 'scale-down' : 'scale-up'}`}>Our Services</h1>
        {
           blogData.map((con,index) => (
            
            <div className="blog-post" key={index}>
               <div className={`image-content scroll-aniamtion ${scrollY > 100 && scrollY < 20000 ? ' scale-up' : 'scale-down'}`}>
                <img
                  src={con.src}
                  alt={con.title}
                  className='round-image'
                />
              </div> 
              <div className="text-content">
                <h2 className={`scroll-animation ${scrollY > 100 ? ' scale-up' : 'scale-down'}`}>{con.title}</h2>
                <p className={`scroll-animation ${scrollY > 100  ? 'scale-up' : 'scale-down'}`}>
                  {con.description}
                </p>
              </div>
            </div>
           ))
        }
      <div className="choose">
           <h4>Why Choose Glatic?</h4>
                    <div className='row'>
                        <WithValuesCard/>
                    </div>   
       
             
         {/* <h4>Why Choose Glatic?</h4>
         <h4>Expertise: Our team comprises seasoned professionals with years of experience in their respective domains.</h4>
         <h4>Innovation: We stay updated with the latest industry trends and technologies to deliver cutting-edge solutions.</h4>
         <h4>Quality: We are committed to delivering high-quality, bug-free products that meet and exceed your expectations.</h4>
         <h4>Client-Centric: Your success is our success, and we prioritize open communication and client satisfaction.</h4>
         <h4>Affordability: We offer competitive pricing without compromising on the quality of our services. </h4>
         <h4>At Glatic, we don't just build software; we build relationships. We're excited to partner with you on your digital journey. Contact us today to discuss your project or learn more about how we can help you achieve your digital goals.</h4> */}
      </div>
      
      
      <div className='row py-5 contact-row '>
          <div className='col-md-6 col-sm-12'>
            <h1>Curious how our service can benefit your needs?</h1>
             <Button href='/contactus' className="hero-button border border-info">Contact now</Button>
          </div>
          <div className='col-md-6 col-sm-12 about-contact-info'>
              <h1>Glatic</h1>
              <ul className='list-unstyled text-small '>
                   <li> <h6>
                   Email: <a href='mailto:contact.glatic@gmail.com'> contact.glatic@gmail.com</a>
                   </h6> 
                   </li>
                   <li>
                   <h6>Phone:
                   <a href='tel:+91 8208146327'> +91 8208146327</a></h6>
                   </li>
                   <li color='aqua'>
                     <h6> Nagpur Maharashtra, India</h6>
                   </li>
              </ul>    
          </div>
          {/* <div className='col-4 col-md'>
          <h5>Phone</h5>
              <ul className='list-unstyled text-small'>
                   <li>
                   <a href='tel:+91 8202146327'> +91 8202146327</a>
                   </li>
              </ul>    
          </div>
          <div className='col-4 col-md'>
          <h5 >Address</h5>
              <ul className='list-unstyled text-small '>
                   <li color='aqua'>
                  Gokulpeth street, Nagpur Maharashtra India
                   </li>
              </ul>    
          </div> */}
      </div> 
      <br></br>
      <footer  id="footers">
      <i>
      <svg  xmlns="http://www.w3.org/2000/svg"  width="26" height="26" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
      </svg>
      </i>
      <i>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
        <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z"/>
        <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"/>
      </svg>
      </i>
      <i>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
      </svg>
      </i>
      <p>© Copyright {(new Date().getFullYear())} Glatic</p>
  </footer>
      
      


      {/* Add more alternating posts here */}
   </div> 
  );
};

// const BlogComponent = ({ contents }) => {
//   contents.map( (con, index) => {
//       <div className="blog-post" key={index}>
//       <div className="image-content">
//         <img
//           src= {con.image}
//           alt={con.title}
//         />
//       </div>
//       <div className="text-content">
//         <h2>{con.title}</h2>
//         <p>
//           {con.description}
//         </p>
//       </div>
//     </div>
//   } )
// }




export default BlogPage;
