import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Alert } from "react-bootstrap";
import '../css/contactForm.css';
import emailJs from '@emailjs/browser'
import { useRef } from "react";


const ContactForm = () => {
  const { register, handleSubmit, errors,} = useForm();
  const form = useRef();
  const onSubmit = () => {
    console.log("on mail service id " + process.env.REACT_APP_MAIL_SERVICE_ID)
    console.log("on mail public key id " + process.env.REACT_APP_MAIL_PUBLIC_KEY)
    if(errors) {
      alert("Somethin went wrong " + errors)
    }else{
      emailJs.sendForm(process.env.REACT_APP_MAIL_SERVICE_ID, 
        process.env.REACT_APP_MAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_MAIL_PUBLIC_KEY);
        alert("Your message send successfully")
    }
    
  };

  return (
    <div className="contactPage">
        <div className="contact-form">
      <h2>Contact Us</h2>
      <Form ref={form} className="form-decor" onSubmit={handleSubmit(onSubmit)} >
        <Form.Group controlId="formName" className="py-2">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="Your Name"
            {...register("message", {required:'Required'})}
          />
          {errors && <Alert variant="danger">Name is required</Alert>}
        </Form.Group>

        <Form.Group controlId="formEmail" className="py-2">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Your Email"
            {...register("message", {required:'Required'})}
          />
          {errors && <Alert variant="danger">Email is required</Alert>}
        </Form.Group>

        <Form.Group controlId="formMessage" className="py-2">
          <Form.Label  >Message</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            placeholder="Your Message"
            {...register("message", {required:'Required'})}
          />
          {errors && <Alert variant="danger">Message is required</Alert>}
        </Form.Group>
        <div className="d-grid gap-2 py-5">
        <Button  type="submit" className=" button-decor border border-info my-2" size="lg"  >
          Send
        </Button>
        </div>
        
      </Form>
    </div>
    </div>
    
  );
};

export default ContactForm;